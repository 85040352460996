// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyCx7cO-csWrDkLHJuoFOvqbrcLLcppwsVo",
    authDomain: "bookyourslot-de1e6.firebaseapp.com",
    projectId: "bookyourslot-de1e6",
    storageBucket: "bookyourslot-de1e6.appspot.com",
    messagingSenderId: "613863653721",
    appId: "1:613863653721:web:8594b1a95f48312ce08c48",
    measurementId: "G-M5DMP02J5V"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
