import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription, interval } from 'rxjs';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { CancelBookingDialogComponent } from './cancel-booking-dialog/cancel-booking-dialog.component';
import { ViewVenuePricingComponent } from './view-venue-pricing/view-venue-pricing.component';
import { ApplyCrmCouponComponent } from './apply-crm-coupon/apply-crm-coupon.component';

@Component({
  selector: 'app-crm',
  templateUrl: './crm.component.html',
  styleUrls: ['./crm.component.scss']
})
export class CrmComponent implements OnInit {

  bookedSlotFetchSubscription: Subscription;

  bookingResult: any;
  selectedCoupon: any;

  isFullPaymentSelected: boolean = true;

  isPreviousBookingLoad: boolean = false;
  isSlotLoading: boolean = false;
  isSlotLoadingForProgram: boolean = false;
  isVenueLoading: boolean = false;
  isUserDataLoading: boolean = false;
  isBookingLoading: boolean = false;

  isUserDataSearched: boolean = false;
  isVenueDataSearched: boolean = false;

  userDetails: any;
  userId: string = '';
  turfSlotTotalAmount: number = 0;

  listOfBookedTimeSlots: any = [];
  listOfBlockedSlots: any = [];
  listOfPreviousBookings: any = [];


  venueSearchString: string = '';
  userSearchString: string = '';

  listOfVenue: any = [];
  listOfUser: any = [];

  venueTimer: any;
  userTimer: any;


  venue: boolean = true;


  selectedDate: any = new Date();
  selectedVenue: any;
  selectedSport: any;
  selectedTurfSize: any;
  selectedSession: any = 'All';
  selectedTimeSlot: any = [];

  selectedUserDetail: any;
  userDetailForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$'),]),
    email: new FormControl('', [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),]),
  });
  maxStartDate: any = new Date();
  httpHeaders: any;
  constructor(
    public appService: AppService, private matDialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.getSessionBasedOnCurrentTime();
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
    this.bookedSlotFetchSubscription = interval(5000).subscribe((x => {
      if (!this.isSlotLoadingForProgram) {
        this.fetchBlockedTimeSlots(false);
      }
    }));
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
  }
  getSessionBasedOnCurrentTime() {
    var today = new Date()
    var curHr = today.getHours()

    if (curHr < 12) {
      this.selectedSession = 'Morning';
    } else if (curHr < 16) {
      this.selectedSession = 'Afternoon';
    } else if (curHr < 20) {
      this.selectedSession = 'Evening';
    } else {
      this.selectedSession = 'Night';
    }
  }
  ngOnInit(): void {

  }
  ngOnDestroy() {
    this.bookedSlotFetchSubscription.unsubscribe();
  }
  dateChangeHandler(event: any) {
    this.selectedDate = event.value;
    if (this.selectedDate != null && this.selectedDate != undefined) {
      this.searchVenueDataOnEnterHadler();
    }
    console.log(event.value);
  }

  toggleVenueAndUserInfo() {
    this.venue = !this.venue;
  }

  searchVenueDataHandler(target: any) {
    let serachQuery = target.value;
    if (serachQuery == '') {
      this.venueSearchString = '';
      this.listOfVenue = [];
      this.selectedVenue = null;
      this.selectedSport = null;
      this.selectedTurfSize = null;
      return;
    }
  }
  async searchVenueDataOnEnterHadler() {
    this.isVenueLoading = true;
    if (this.venueSearchString == '') {
      this.isVenueLoading = false;
      return;
    } else {
      clearTimeout(this.venueTimer);
      this.selectedVenue = null;
      try {
        this.listOfVenue = [];
        let param = {
          searchedString: this.venueSearchString.trim(),
          date: this.selectedDate
        };
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        let URL;
        URL = `${webApi.domain + webApi.endPoint.searchVenueByNameAndLocation}`;
        await this.appService
          .postMethodWithToken(param, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              data['result'].forEach((venue: any) => {
                venue['showMoreAddress'] = false;
                venue['isSelected'] = false;
                venue['turfSession'] = [{ title: 'All' }];
                venue.slots.forEach((slt: any) => {
                  let isExist: boolean = false;
                  venue['turfSession'].forEach((trf: any) => {
                    if (trf['title'] == slt.session) {
                      isExist = true;
                    }
                  });
                  if (!isExist) {
                    venue['turfSession'].push({
                      title: slt.session, src: slt.session == 'Morning' ? '../../../../assets/svgIcons/morning.svg'
                        : (slt.session == 'Afternoon' ? '../../../../assets/svgIcons/afternoon.svg'
                          : (slt.session == 'Evening' ? '../../../../assets/svgIcons/evening.svg' : '../../../../assets/svgIcons/night.svg'))
                    });
                  }
                });
                venue['turfSize'] = [];
                venue.slots.forEach((slt: any) => {
                  slt.priceAndQuantity.forEach((price: any) => {
                    let isExist = false;
                    venue['turfSize'].forEach((trf: any) => {
                      if (trf['title'] == price.title) {
                        isExist = true;
                      }
                    });
                    if (!isExist) {
                      venue['turfSize'].push({ title: price.title, isSelected: false, label: price.label, quantity: price.quantity, session: slt.session });
                    }
                  });
                });
                venue.sportCategory['isSelected'] = false;
                venue.sportsType.forEach((type: any) => {
                  type['isSelected'] = false;
                });
                venue['slotTime'] = [];
                venue.slots.forEach((slt: any) => {
                  let allTime = this.getAllTime(slt['startTime'], slt['endTime'], 30, slt['session'], slt['priceAndQuantity']);
                  venue['slotTime'].push(...allTime);
                });
                venue['slotTime'].sort((a: any, b: any) => {
                  return a.timeToSort - b.timeToSort;
                });
                this.listOfVenue.push(venue);
              });
            }
            this.isVenueLoading = false;
            this.isVenueDataSearched = true;
          });
      } catch (error) {
        this.isVenueLoading = false;
        this.isVenueDataSearched = true;
      }
    }
  }
  searchVenueKeydownHandler() {
    clearTimeout(this.venueTimer);
    this.venueTimer = setTimeout(async () => {
      this.searchVenueDataOnEnterHadler();
    }, 2000);
  }
  async searchUserDataOnEnterHadler() {
    this.isUserDataLoading = true;
    this.isUserDataSearched = true;
    this.userDetailForm.get('name')?.setValue('');
    this.userDetailForm.get('phone')?.setValue('');
    this.userDetailForm.get('email')?.setValue('');
    if (this.userSearchString == '') {
      this.isUserDataLoading = true;
      return;
    } else {
      clearTimeout(this.userTimer);
      try {
        this.listOfUser = [];
        let param = {
          searchedString: this.userSearchString.trim(),
        };
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        let URL;
        URL = `${webApi.domain + webApi.endPoint.searchUserByNameAndPhone}`;
        await this.appService
          .postMethodWithToken(param, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              data['result'].forEach((user: any) => {
                this.listOfUser.push(user);
              });
              console.log(this.listOfUser);
              if (this.listOfUser.length == 1) {
                this.selectUserHandler(this.listOfUser[0]);
              }
              // this.getPreviousBookingsOfUser();
            }
            this.isUserDataLoading = false;
            this.isUserDataSearched = true;
          });
      } catch (error) {
        this.isUserDataLoading = false;
        this.isUserDataSearched = true;
      }
    }
  };
  searchUserDataHandler(target: any) {
    let serachQuery = target.value;
    if (serachQuery == '') {
      this.userSearchString = '';
      this.listOfUser = [];
      return;
    }
  }
  searchUserKeydownHandler() {
    clearTimeout(this.userTimer);
    this.userTimer = setTimeout(async () => {
      this.searchUserDataOnEnterHadler();
    }, 2000);
  }

  getAllTime(startTime: any, endTime: any, intervalMinutes: any, session: any, priceAndQuantity: any) {
    var intervals = [];
    // let date: any = `${new Date().getMonth() + 1}-${new Date().getDate()}-${new Date().getFullYear()}`;
    // let nextDate: any = `${new Date(new Date(this.selectedDate).setDate(new Date(this.selectedDate).getDate() + 1)).getMonth() + 1}-${new Date(new Date(this.selectedDate).setDate(new Date(this.selectedDate).getDate() + 1)).getDate()}-${new Date(new Date(this.selectedDate).setDate(new Date(this.selectedDate).getDate() + 1)).getFullYear()}`;

    // let currentTime = new Date(`${date} ${startTime}:00:00`);
    // const endTimeObj = startTime > endTime ? new Date(`${nextDate} ${endTime}:00:00`) : new Date(`${date} ${endTime}:00:00`);


    let date: any = `${new Date(this.selectedDate).getFullYear()}-${String(new Date(this.selectedDate).getMonth() + 1).length == 1 ? '0' + (new Date(this.selectedDate).getMonth() + 1) : new Date(this.selectedDate).getMonth() + 1}-${new Date(this.selectedDate).getDate()}`;
    let nextDate: any = `${new Date(new Date(this.selectedDate).setDate(new Date(this.selectedDate).getDate() + 1)).getFullYear()}-${String(new Date(new Date(this.selectedDate).setDate(new Date(this.selectedDate).getDate() + 1)).getMonth() + 1).length == 1 ? '0' + (new Date(new Date(this.selectedDate).setDate(new Date(this.selectedDate).getDate() + 1)).getMonth() + 1) : new Date(new Date(this.selectedDate).setDate(new Date(this.selectedDate).getDate() + 1)).getMonth() + 1}-${new Date(new Date(this.selectedDate).setDate(new Date(this.selectedDate).getDate() + 1)).getDate()}`;

    let currentTime = new Date(`${date} ${String(startTime).length == 1 ? '0' + startTime : startTime}:00:00`);
    const endTimeObj = startTime > endTime ? new Date(`${nextDate} ${String(endTime).length == 1 ? '0' + endTime : endTime}:00:00`) : new Date(`${date} ${String(endTime).length == 1 ? '0' + endTime : endTime}:00:00`);

    while (currentTime < endTimeObj) {
      const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) + ' - ' +
        (new Date(new Date(currentTime).setMinutes(new Date(currentTime).getMinutes() + intervalMinutes))).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

      // console.log(currentTime);
      let stTime = parseFloat(new Date(currentTime).getHours() + '.' + (String(new Date(currentTime).getMinutes()).length > 1 ? parseFloat(String(new Date(currentTime).getMinutes()).substring(0, 1)) : new Date(currentTime).getMinutes()));
      let edTime = parseFloat(new Date(new Date(currentTime).setMinutes(new Date(currentTime).getMinutes() + intervalMinutes)).getHours() + '.' +
        (String(new Date(new Date(currentTime).setMinutes(new Date(currentTime).getMinutes() + intervalMinutes)).getMinutes()).length > 1 ?
          parseFloat(String(new Date(new Date(currentTime).setMinutes(new Date(currentTime).getMinutes() + intervalMinutes)).getMinutes()).substring(0, 1))
          : new Date(new Date(currentTime).setMinutes(new Date(currentTime).getMinutes() + intervalMinutes)).getMinutes()));
      let priceDt: any = {};
      priceAndQuantity.forEach((size: any) => {
        if (priceDt[size.title] == null || priceDt[size.title] == undefined) {
          priceDt[size.title] = {
            weekDay: size['price'],
            weekEnd: size['weekendPrice'],
          }
        }
      });
      let currentDate = startTime > endTime ? new Date(`${nextDate} ${String(stTime).replace('.', ':')}:00`) : new Date(`${date} ${String(stTime).replace('.', ':')}:00`);
      let isBlocked = false;
      if (new Date() > currentDate) {
        isBlocked = true;
      }
      intervals.push({ startTime: stTime, endTime: edTime, timeToSort: this.getValue(stTime, session), timeLabel: formattedTime, session: session, isSelected: false, isBookedByMe: false, isBlocked: isBlocked, ...priceDt, actualStartTime: startTime, actualEndTime: endTime });
      currentTime.setMinutes(currentTime.getMinutes() + intervalMinutes);
    }

    return intervals;
  }
  getValue(value: any, session: any) {
    if (value == 0 && session != 'Morning') {
      return 24;
    } else if (value == 0.3 && session != 'Morning') {
      return 24.3;
    } else if (value == 1 && session != 'Morning') {
      return 25;
    } else if (value == 1.3 && session != 'Morning') {
      return 25.3;
    } else if (value == 2 && session != 'Morning') {
      return 26;
    } else if (value == 2.3 && session != 'Morning') {
      return 26.3;
    } else if (value == 3 && session != 'Morning') {
      return 27;
    } else if (value == 3.3 && session != 'Morning') {
      return 27.3;
    } else if (value == 4 && session != 'Morning') {
      return 28;
    } else if (value == 4.3 && session != 'Morning') {
      return 28.3;
    } else if (value == 5 && session != 'Morning') {
      return 29;
    } else {
      return value;
    }
  }
  searchSessionDataHandler(session: any) {
    this.selectedSession = session;
  }

  selectVenueHandler(venue: any) {
    this.listOfVenue.forEach((element: any) => {
      element['isSelected'] = false;
      if (venue['_id'] == element['_id']) {
        element['isSelected'] = true;
        this.selectedVenue = element;
      }
    });
    this.selectedSport = null;
    this.selectedTurfSize = null;
    this.selectedVenue['slotTime'].forEach((tym: any) => {
      tym['isBlocked'] = false;
    });
  }
  toggleShowMore(venue: any) {
    this.listOfVenue.forEach((element: any) => {
      if (venue['_id'] == element['_id']) {
        element['showMoreAddress'] = !element['showMoreAddress'];
      }
    });
  }

  selectUserHandler(user: any) {
    this.listOfUser.forEach((element: any) => {
      if (element['_id'] == user['_id']) {
        this.selectedUserDetail = user;
      }
    });
    if (this.selectedUserDetail != null && this.selectedUserDetail != undefined) {
      this.userDetailForm.get('name')?.setValue(this.selectedUserDetail.firstName + ' ' + this.selectedUserDetail.lastName);
      this.userDetailForm.get('phone')?.setValue(this.selectedUserDetail.phone);
      this.userDetailForm.get('email')?.setValue(this.selectedUserDetail.email);
      this.getPreviousBookingsOfUser();
    } else {
      this.userDetailForm.get('name')?.setValue('');
      this.userDetailForm.get('phone')?.setValue('');
      this.userDetailForm.get('email')?.setValue('');
    }
  }

  async getPreviousBookingsOfUser() {
    this.isPreviousBookingLoad = true;
    if (this.selectedUserDetail == undefined || this.selectedUserDetail == null) {
      this.isPreviousBookingLoad = false;
      return;
    } else {
      this.listOfPreviousBookings = [];
      try {
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        let URL;
        URL = `${webApi.domain + webApi.endPoint.fetchBookingDetailsOfUser}/${this.selectedUserDetail['_id']}`;
        console.log(URL);
        await this.appService
          .getMethodWithToken(URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              data['result'].forEach((booking: any) => {
                this.listOfPreviousBookings.push(booking);
              });
              console.log(this.listOfPreviousBookings);
            }
            this.isPreviousBookingLoad = false;
          });
      } catch (error) {
        this.isPreviousBookingLoad = false;
      }
    }
  }
  isAdvancePayment() {
    let isExist: boolean = false;
    if (this.selectedVenue['convenience'] != null && this.selectedVenue['convenience'] != undefined) {
      this.selectedVenue['convenience']['paymentOption'].forEach((element: any) => {
        if (element == 'Advance') {
          isExist = true;
        }
      });
    }
    return isExist;
  }
  setPaymentOption(src: any) {
    if (src == 'Full Payment') {
      this.isFullPaymentSelected = true;
    } else {
      this.isFullPaymentSelected = false;
    }
  }
  selectSportType(sportName: any) {
    this.selectedVenue['turfSize'].forEach((turf: any) => {
      turf['isSelected'] = false;
    });
    this.selectedVenue['slotTime'].forEach((slotTimeIndividual: any) => {
      slotTimeIndividual['isSelected'] = false;
      slotTimeIndividual['isBlocked'] = false;
    });
    this.selectedTimeSlot = [];
    this.selectedVenue.sportsType.forEach((element: any) => {
      element['isSelected'] = false;
      if (element['sport'] == sportName['sport']) {
        element['isSelected'] = true;
        this.selectedSport = element;
      }
    });
  }
  selectSportTurfSize(size: any) {
    this.selectedVenue['slotTime'].forEach((slotTimeIndividual: any) => {
      slotTimeIndividual['isSelected'] = false;
      slotTimeIndividual['isBlocked'] = false;
    });
    this.selectedTimeSlot = [];
    this.selectedVenue.turfSize.forEach((element: any) => {
      element['isSelected'] = false;
      if (element['title'] == size['title']) {

        element['isSelected'] = true;
        this.selectedTurfSize = size;
      }
    });
    this.fetchBlockedTimeSlots(true);
  }
  removeColonToX(src: string) {
    return String(src).replace(':', 'X');
  }
  getSlotTimeBasedOnSession() {
    let slotsDt: any = [];
    if (this.selectedSession == 'All') {
      slotsDt = this.selectedVenue['slotTime'];
    } else {

      this.selectedVenue['slotTime'].forEach((element: any) => {
        if (element['session'] == this.selectedSession) {
          slotsDt.push(element);
        }
      });
    }
    return slotsDt;
  }
  isWeekend() {
    if (this.selectedDate != null && this.selectedDate != undefined) {
      if (this.selectedDate.getDay() == 6 || this.selectedDate.getDay() == 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  selectSlotHandler(slot: any, add: boolean) {
    if (this.selectedVenue == null || this.selectedVenue == undefined) {
      this.showWarningToaster('Please select venue', 'Warning');
      return;
    }
    if (this.selectedSport == null || this.selectedSport == undefined) {
      this.showWarningToaster('Please select sport', 'Warning');
      return;
    }
    if (this.selectedTurfSize == null || this.selectedTurfSize == undefined) {
      this.showWarningToaster('Please select turf size', 'Warning');
      return;
    }





    if (add) {
      let idx = -1;
      this.selectedTimeSlot.forEach((tym: any, indx: number) => {
        if (tym['startTime'] == slot['startTime']) {
          idx = indx;
        }
      });
      if (idx == -1) {
        this.selectedTimeSlot.push(slot);
      }
      this.selectedTimeSlot.sort((a: any, b: any) => {
        return a.timeToSort - b.timeToSort;
      });
      const abc = [];
      for (let i = 0; i < this.selectedTimeSlot.length; i++) {
        abc.push(this.selectedTimeSlot[i]['startTime']);
      }

      let showAlert = false;
      for (let i = 0; i < abc.length; i++) {
        if (i + 1 < abc.length) {
          if (parseFloat((abc[i + 1] - abc[i]).toFixed(2)) > 0.7) {
            showAlert = true;
          }
        }
      }

      if (!showAlert) {
        this.selectedVenue['slotTime'].forEach((slotTimeIndividual: any) => {
          if (slotTimeIndividual['timeLabel'] == slot['timeLabel']) {
            slotTimeIndividual['isSelected'] = !slotTimeIndividual['isSelected'];
          }
        });
      } else {
        let idx = -1;
        this.selectedTimeSlot.forEach((tym: any, indx: number) => {
          if (tym['startTime'] == slot['startTime']) {
            idx = indx;
          }
        });
        if (idx != -1) {
          this.selectedTimeSlot.splice(idx, 1);
        }
        this.selectedTimeSlot.sort((a: any, b: any) => {
          return a.timeToSort - b.timeToSort;
        });
        this.showWarningToaster('You can only add consecutive timing.', 'Warning');
      }
    } else {
      let idx = -1;
      this.selectedTimeSlot.forEach((tym: any, indx: number) => {
        if (tym['startTime'] == slot['startTime']) {
          idx = indx;
        }
      });
      if (idx != -1) {
        this.selectedTimeSlot.splice(idx, 1);
      }
      this.selectedTimeSlot.sort((a: any, b: any) => {
        return a.timeToSort - b.timeToSort;
      });
      const abc = [];
      for (let i = 0; i < this.selectedTimeSlot.length; i++) {
        abc.push(this.selectedTimeSlot[i]['startTime']);
      }

      let showAlert = false;
      for (let i = 0; i < abc.length; i++) {
        if (i + 1 < abc.length) {
          if (parseFloat((abc[i + 1] - abc[i]).toFixed(2)) > 0.7) {
            showAlert = true;
          }
        }
      }

      if (!showAlert) {
        this.selectedVenue['slotTime'].forEach((slotTimeIndividual: any) => {
          if (slotTimeIndividual['timeLabel'] == slot['timeLabel']) {
            slotTimeIndividual['isSelected'] = !slotTimeIndividual['isSelected'];
          }
        });
      } else {
        let idx = -1;
        this.selectedTimeSlot.forEach((tym: any, indx: number) => {
          if (tym['startTime'] == slot['startTime']) {
            idx = indx;
          }
        });
        if (idx == -1) {
          this.selectedTimeSlot.push(slot);
        }
        this.selectedTimeSlot.sort((a: any, b: any) => {
          return a.timeToSort - b.timeToSort;
        });
        this.showWarningToaster('You can only unselect consecutive timing.', 'Warning');
      }
    }

  }
  getTotalAmount() {
    this.turfSlotTotalAmount = 0;
    if (this.selectedVenue != null && this.selectedVenue != undefined && this.selectedTurfSize != null && this.selectedTurfSize != undefined) {
      this.selectedTimeSlot.forEach((element: any) => {
        if (this.isWeekend()) {
          this.turfSlotTotalAmount = this.turfSlotTotalAmount + element[this.selectedTurfSize['title']]['weekEnd'];
        } else {
          this.turfSlotTotalAmount = this.turfSlotTotalAmount + element[this.selectedTurfSize['title']]['weekDay'];
        }
      });
    }
    return this.turfSlotTotalAmount;
  }
  getPaidAmount() {
    this.turfSlotTotalAmount = 0;
    if (this.selectedVenue != null && this.selectedVenue != undefined && this.selectedTurfSize != null && this.selectedTurfSize != undefined) {
      if (this.isFullPaymentSelected) {
        this.selectedTimeSlot.forEach((element: any) => {
          if (this.isWeekend()) {
            this.turfSlotTotalAmount = this.turfSlotTotalAmount + element[this.selectedTurfSize['title']]['weekEnd'];
          } else {
            this.turfSlotTotalAmount = this.turfSlotTotalAmount + element[this.selectedTurfSize['title']]['weekDay'];
          }
        });
      } else {
        this.turfSlotTotalAmount = this.getAdvanceAmount();
      }
    }
    return this.turfSlotTotalAmount;
  }
  getAdvanceAmount() {
    let amount = 0;
    if (this.selectedVenue['convenience'] != null && this.selectedVenue['convenience'] != undefined) {
      amount = (this.selectedTurfSize['title'] == '1:1' ? this.selectedVenue['advanceAmountForOneOn'] : this.selectedVenue['advanceAmount'])
    }

    return amount;
  }
  async fetchBlockedTimeSlots(isLoad: boolean) {

    if ((this.selectedVenue != null && this.selectedVenue != undefined)
      && (this.selectedSport != null && this.selectedSport != undefined)
      && (this.selectedTurfSize != null && this.selectedTurfSize != undefined)
    ) {

      await this.fetchTurfBookingSlot(isLoad);
      console.log('listOfBlockedSlots', this.listOfBlockedSlots);
      console.log('listOfBookedTimeSlots', this.listOfBookedTimeSlots);



      this.selectedVenue['slotTime'].forEach((tym: any) => {
        let isExist: boolean = false;
        let isBookedByMe: boolean = false;
        this.listOfBookedTimeSlots.forEach((bkdTym: any) => {
          if (bkdTym['isNet']) {
            if (bkdTym['quantities'] != null && bkdTym['quantities'][this.selectedTurfSize['title']] <= 0) {
              if ((tym['startTime'] == bkdTym['startTime']) && (tym['endTime'] == bkdTym['endTime'])) {
                isExist = true;
                if (bkdTym['bysEmpId'] == this.userDetails['userId']) {
                  isBookedByMe = true;
                }
              }
            }
          } else {
            if (bkdTym['sizeOrSport'] == this.selectedTurfSize['title'] && bkdTym['remainingCount'] <= 0) {
              if ((tym['startTime'] == bkdTym['startTime']) && (tym['endTime'] == bkdTym['endTime'])) {
                isExist = true;
                if (bkdTym['bysEmpId'] == this.userDetails['userId']) {
                  isBookedByMe = true;
                }
              }
            }
          }

        });
        if (isExist) {
          tym['isBlocked'] = true;
        } else {
          tym['isBlocked'] = false;
        }
        if (isBookedByMe) {
          tym['isBookedByMe'] = true;
        } else {
          tym['isBookedByMe'] = false;
        }
      });



      this.selectedVenue['slotTime'].forEach((tym: any) => {
        let isExist: boolean = false;
        this.listOfBlockedSlots.forEach((blckdTym: any, idx: any) => {
          if ((tym['startTime'] == blckdTym['startTime']) && (tym['endTime'] == blckdTym['endTime'])) {
            isExist = true;
          }
        });
        if (this.listOfBlockedSlots.length > 0) {

          if (isExist) {
            tym['isBlocked'] = true;
          } else {
            tym['isBlocked'] = false;
          }

        }
      });

      this.selectedVenue['slotTime'].forEach((tym: any) => {

        // let date: any = `${new Date(this.selectedDate).getMonth() + 1}-${new Date(this.selectedDate).getDate()}-${new Date(this.selectedDate).getFullYear()}`;
        // let nextDate: any = `${new Date(new Date(this.selectedDate).setDate(new Date(this.selectedDate).getDate() + 1)).getMonth() + 1}-${new Date(new Date(this.selectedDate).setDate(new Date(this.selectedDate).getDate() + 1)).getDate()}-${new Date(new Date(this.selectedDate).setDate(new Date(this.selectedDate).getDate() + 1)).getFullYear()}`;

        let date: any = `${new Date(this.selectedDate).getFullYear()}-${String(new Date(this.selectedDate).getMonth() + 1).length == 1 ? '0' + (new Date(this.selectedDate).getMonth() + 1) : new Date(this.selectedDate).getMonth() + 1}-${new Date(this.selectedDate).getDate()}`;
        let nextDate: any = `${new Date(new Date(this.selectedDate).setDate(new Date(this.selectedDate).getDate() + 1)).getFullYear()}-${String(new Date(new Date(this.selectedDate).setDate(new Date(this.selectedDate).getDate() + 1)).getMonth() + 1).length == 1 ? '0' + (new Date(new Date(this.selectedDate).setDate(new Date(this.selectedDate).getDate() + 1)).getMonth() + 1) : new Date(new Date(this.selectedDate).setDate(new Date(this.selectedDate).getDate() + 1)).getMonth() + 1}-${new Date(new Date(this.selectedDate).setDate(new Date(this.selectedDate).getDate() + 1)).getDate()}`;



        let currentDate1 = tym['actualStartTime'] > tym['actualEndTime'] ? new Date(`${nextDate} ${String((String(tym['startTime']).includes('.') ? (tym['startTime'] + '0') : tym['startTime'])).replace('.', ':')}:00`) : new Date(`${date} ${String((String(tym['startTime']).includes('.') ? (tym['startTime'] + '0') : tym['startTime'])).replace('.', ':')}:00`);
        let currentDate2 = tym['actualStartTime'] > tym['actualEndTime'] ? new Date(`${nextDate} ${String((String(tym['endTime']).includes('.') ? (tym['endTime'] + '0') : tym['endTime'])).replace('.', ':')}:00`) : new Date(`${date} ${String((String(tym['endTime']).includes('.') ? (tym['endTime'] + '0') : tym['endTime'])).replace('.', ':')}:00`);
        if (new Date() > currentDate1 || new Date(new Date().setMinutes(new Date().getMinutes() + 30)) > currentDate2) {
          tym['isBlocked'] = true;
        }
      });
    }
  }
  async fetchTurfBookingSlot(isLoad: boolean) {
    this.isSlotLoading = isLoad;
    this.isSlotLoadingForProgram = true;
    try {
      let isNet = this.selectedVenue.sportCategory.categoryName != 'Outdoor'
        ? false
        : this.selectedVenue.isNet;

      this.listOfBookedTimeSlots = [];
      let param = {};
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.fetchTurfBookingSlot}`;
      param = {
        turfId: this.selectedVenue['_id'], startDate: new Date(new Date(this.selectedDate).setHours(0, 0, 0, 0)),
        endDate: new Date(new Date(this.selectedDate).setHours(23, 59, 59)),
        sizeOrSport: this.selectedTurfSize['title'], isNet: isNet
      };
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then(async (data: any) => {
          if (data['success']) {
            this.listOfBookedTimeSlots = data['result'];
          }
          await this.fetchBlockedSlot(isLoad);
        });
    } catch (error) {
      await this.fetchBlockedSlot(isLoad);
    }
  }
  async fetchBlockedSlot(isLoad: boolean) {
    this.isSlotLoading = isLoad;
    this.isSlotLoadingForProgram = true;
    try {
      this.listOfBlockedSlots = [];
      let isNet = this.selectedVenue.sportCategory.categoryName != 'Outdoor'
        ? false
        : this.selectedVenue.isNet;
      let sport = this.selectedVenue.sportCategory.categoryName == 'Outdoor'
        ? this.selectedSport == null
          ? ''
          : this.selectedSport.name
        : this.selectedTurfSize['title'];
      let param = {};
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.fetchBlockedSlot}`;
      param = {
        turfId: this.selectedVenue['_id'], startDate: new Date(new Date(this.selectedDate).setHours(0, 0, 0, 0)),
        endDate: new Date(new Date(this.selectedDate).setHours(23, 59, 59)),
        sizeOrSport: this.selectedTurfSize['title'], isNet: isNet, sport: sport
      };
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfBlockedSlots = data['result'];
          }
          this.isSlotLoading = false;
          this.isSlotLoadingForProgram = false;
        });
    } catch (error) {
      this.isSlotLoading = false;
      this.isSlotLoadingForProgram = false;
    }
  }
  async createBookingAndSendPaymentLink() {
    this.isBookingLoading = true;
    if (this.selectedVenue == null) {
      this.isBookingLoading = false;
      return;
    }
    if (this.turfSlotTotalAmount <= 0) {
      this.isBookingLoading = false;
      return;
    }
    let isAllSlotFree: boolean = true;
    this.selectedTimeSlot.forEach((element: any) => {
      if (element['isSelected'] && element['isBlocked']) {
        isAllSlotFree = false;
      }
    });
    if (!isAllSlotFree) {
      this.showWarningToaster('Please Check one or more selected slot times is already booked.', 'Warning');
      this.isBookingLoading = false;
      return;
    }


    try {
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let convenienceFee: number = 0;
      let gstOnConvenienceFee: number = 0;

      if (this.selectedVenue['convenience'] != null && this.selectedVenue['convenience'] != undefined) {
        if (!this.selectedVenue['convenience']['sameForAll']) {
          this.selectedVenue['convenience']['charges'].forEach((chrg: any) => {
            if (chrg['sport'] == this.selectedSport['sport']) {
              if (this.selectedSport['sport'] == 'Cricket' && this.selectedTurfSize['title'] == '1:1') {
                convenienceFee = this.isFullPaymentSelected ? chrg['fullPayment1v1'] : chrg['advancePayment1v1'];
              } else {
                convenienceFee = this.isFullPaymentSelected ? chrg['fullPayment'] : chrg['advancePayment'];
              }
            }
          });
          convenienceFee = (this.selectedTimeSlot.length > 0 ? this.selectedTimeSlot.length : 1) * convenienceFee;
          gstOnConvenienceFee = (convenienceFee * 18 / 100);
        } else {
          convenienceFee = (this.selectedTimeSlot.length > 0 ? this.selectedTimeSlot.length : 1) * (this.isFullPaymentSelected ? this.selectedVenue['convenience']['fullPayment'] : this.selectedVenue['convenience']['advancePayment']);
          gstOnConvenienceFee = (convenienceFee * 18 / 100);
        }
      }


      let basicAmount = 0;
      if (this.selectedVenue != null && this.selectedVenue != undefined && this.selectedTurfSize != null && this.selectedTurfSize != undefined) {
        this.selectedTimeSlot.forEach((element: any) => {
          if (this.isWeekend()) {
            basicAmount = basicAmount + element[this.selectedTurfSize['title']]['weekEnd'];
          } else {
            basicAmount = basicAmount + element[this.selectedTurfSize['title']]['weekDay'];
          }
        });
      }


      let totalAmount = basicAmount + convenienceFee + gstOnConvenienceFee;
      let discountedAmount = 0;
      if (this.selectedCoupon != null && this.selectedCoupon != undefined) {
        if (this.selectedCoupon['benefitType'] == 'Percentage') {
          let disAmt = (basicAmount * this.selectedCoupon['discountPercentage']) / 100;
          if (disAmt > this.selectedCoupon['benefitAmount']) {
            discountedAmount = this.selectedCoupon['benefitAmount'];
          } else {
            discountedAmount = disAmt;
          }
        } else {
          discountedAmount = this.selectedCoupon['benefitAmount'];
        }
      }

      let nameArr: any = String(this.userDetailForm.get('name')?.value).split(' ');
      let firstName = nameArr[0];
      let lastName = nameArr[1];
      let isNet = this.selectedVenue.sportCategory.categoryName != 'Outdoor'
        ? false
        : this.selectedVenue.isNet;
      let trfQty: any = {};
      this.selectedVenue.slots.forEach((slot: any) => {
        slot.priceAndQuantity.forEach((size: any) => {
          let isExist = false;
          for (const key in trfQty) {
            if (key == size.title) {
              isExist = true;
            }
          }
          if (!isExist) {
            trfQty[size.title] = size.quantity;
          }
        });
      });
      let turfQuantity = this.selectedVenue.sportCategory.categoryName != 'Outdoor'
        ? this.selectedTurfSize['quantity']
        : (isNet ? trfQty : this.selectedTurfSize['quantity']);
      let startDate = new Date(new Date(this.selectedDate).setHours(0, 0, 0, 0));
      let endDate = new Date(new Date(this.selectedDate).setHours(23, 59, 59));


      // this.selectedTimeSlot.sort((a: any, b: any) => {
      //   return a.startTime - b.startTime;
      // });

      let startTime: any = this.selectedTimeSlot[0]['startTime'];
      let endTime: any = this.selectedTimeSlot[this.selectedTimeSlot.length - 1]['endTime'];


      let amountPaid = 0;
      if (this.isFullPaymentSelected) {
        amountPaid = (basicAmount - this.getDiscountAmount()) + convenienceFee + gstOnConvenienceFee;
      } else {
        amountPaid = this.getAdvanceAmount() + convenienceFee + gstOnConvenienceFee;
      }

      let param: any = {
        // "option": option,
        "firstName": firstName,
        "lastName": lastName,
        "phone": this.userDetailForm.get('phone')?.value,
        "email": this.userDetailForm.get('email')?.value != null && this.userDetailForm.get('email')?.value != undefined && this.userDetailForm.get('email')?.value != '' ? this.userDetailForm.get('email')?.value : undefined,
        "turfId": this.selectedVenue['_id'],
        "business": this.selectedVenue['business'],
        "bookingMode": "Online",
        "bookingStatus": "PENDING",
        "totalAmount": totalAmount,
        "discountedAmount": discountedAmount,
        "coupon": this.selectedCoupon != null && this.selectedCoupon != undefined ? this.selectedCoupon['_id'] : undefined,
        "basicAmount": basicAmount,
        "convenienceFee": convenienceFee,
        "gstOnConvenienceFee": gstOnConvenienceFee,
        "amountPaid": amountPaid,
        "sizeOrSport": this.selectedTurfSize['title'],
        "sport": this.selectedSport['_id'],
        "bookingDate": new Date(new Date(this.selectedDate).setHours(13, 0, 0, 0)),
        "rentedItem": [],
        "bookingSlotStartTime": startTime,
        "bookingSlotEndTime": endTime,
        "sportCategory": this.selectedVenue['sportCategory'],
        "label": this.selectedTurfSize['label'],
        "option": this.selectedVenue['option'],

        // Params for insertFromAdminBookingSlot
        "startTime": startTime,
        "endTime": endTime,
        "isNet": isNet,
        "startDate": startDate,
        "endDate": endDate,
        "turfQuantity": turfQuantity,
      };
      this.isBookingLoading = false;
      let URL = `${webApi.domain + webApi.endPoint.createBookingAndSendPaymentLink}`;
      console.log(URL);
      await this.appService
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.bookingResult = data['result'];
            this.selectedTimeSlot = [];
            this.selectedCoupon = null;
            this.selectedVenue['slotTime'].forEach((tym: any) => {
              tym['isSelected'] = false;
            });
            this.getPreviousBookingsOfUser();
            this.showSuccessToaster('Booking created successfully.', 'Success');
          }
          this.isBookingLoading = false;
        });
    } catch (error) {
      console.log(error);
      this.showWarningToaster('Unable to create booking. Try again.', 'Warning');
      this.isBookingLoading = false;
    }
  }

  getDiscountAmount() {

    let turfSlotTotalAmount = 0;
    if (this.selectedVenue != null && this.selectedVenue != undefined && this.selectedTurfSize != null && this.selectedTurfSize != undefined) {
      this.selectedTimeSlot.forEach((element: any) => {
        if (this.isWeekend()) {
          turfSlotTotalAmount = turfSlotTotalAmount + element[this.selectedTurfSize['title']]['weekEnd'];
        } else {
          turfSlotTotalAmount = turfSlotTotalAmount + element[this.selectedTurfSize['title']]['weekDay'];
        }
      });
    }

    let discountedAmount = 0;
    if (this.selectedCoupon != null && this.selectedCoupon != undefined) {
      if (this.selectedCoupon['benefitType'] == 'Percentage') {
        let disAmt = (turfSlotTotalAmount * this.selectedCoupon['discountPercentage']) / 100;
        if (disAmt > this.selectedCoupon['benefitAmount']) {
          discountedAmount = this.selectedCoupon['benefitAmount'];
        } else {
          discountedAmount = disAmt;
        }
      } else {
        discountedAmount = this.selectedCoupon['benefitAmount'];
      }
    }
    return discountedAmount;
  }

  getConvenienceAmt() {
    let convenienceFee: number = 0;
    let gstOnConvenienceFee: number = 0;

    if (this.selectedVenue != null && this.selectedVenue != undefined) {
      if (this.selectedVenue['convenience'] != null && this.selectedVenue['convenience'] != undefined) {
        if (!this.selectedVenue['convenience']['sameForAll']) {
          this.selectedVenue['convenience']['charges'].forEach((chrg: any) => {
            if (chrg['sport'] == this.selectedSport['sport']) {
              if (this.selectedSport['sport'] == 'Cricket' && this.selectedTurfSize['title'] == '1:1') {
                convenienceFee = this.isFullPaymentSelected ? chrg['fullPayment1v1'] : chrg['advancePayment1v1'];
              } else {
                convenienceFee = this.isFullPaymentSelected ? chrg['fullPayment'] : chrg['advancePayment'];
              }
            }
          });
          convenienceFee = (this.selectedTimeSlot.length > 0 ? this.selectedTimeSlot.length : 1) * convenienceFee;
          gstOnConvenienceFee = (convenienceFee * 18 / 100);
        } else {
          convenienceFee = (this.selectedTimeSlot.length > 0 ? this.selectedTimeSlot.length : 1) * (this.isFullPaymentSelected ? this.selectedVenue['convenience']['fullPayment'] : this.selectedVenue['convenience']['advancePayment']);
          gstOnConvenienceFee = (convenienceFee * 18 / 100);
        }
      }
    }
    return convenienceFee + gstOnConvenienceFee;
  }

  isUpcomingBooking(bookingDate: any) {
    bookingDate = new Date(bookingDate);
    if (new Date() < bookingDate) {
      return true;
    }
    return false;
  }
  cancelBooking(info: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { id: info._id, source: 'Cancel Booking' };

    let dialogRef = this.matDialog.open(
      CancelBookingDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const URL = `${webApi.domain + webApi.endPoint.cancelAndRescheduleBooking}`;
        await this.appService
          .putMethodWithToken({
            "cancel": true,
            "bookingId": info._id,
            "cancelledByUser": true,
            startDate: new Date(new Date(info.bookingDate).setHours(0, 0, 0, 0)),
            endDate: new Date(new Date(info.bookingDate).setHours(23, 59, 59)),
          }, URL, this.httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              this.getPreviousBookingsOfUser();
              this.showSuccessToaster(
                'Booking Cancelled Successfully',
                'Success'
              );
            } else {
              this.showWarningToaster(
                'Unable to cancel booking. Try again',
                'Warning'
              );
            }
          });
      }
    });
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  getJSONText() {
    return JSON.stringify(this.selectedVenue);
  }

  viewPricing() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { turf: this.selectedVenue };
    dialogConfig.width = `50vw`;
    dialogConfig.maxWidth = `70vw`;
    dialogConfig.maxHeight = `90vh`;
    let dialogRef = this.matDialog.open(ViewVenuePricingComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
      }
    });
  }

  applyCouponHandler() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { coupon: this.selectedCoupon, amount: this.getTotalAmount() };

    let dialogRef = this.matDialog.open(
      ApplyCrmCouponComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        this.selectedCoupon = null;
        return;
      } else {
        if (res['result'] != null && res['result'] != undefined) {
          this.selectedCoupon = res['result'];
        }
      }
    });
  }
}
