import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-list-compliance',
  templateUrl: './list-compliance.component.html',
  styleUrls: ['./list-compliance.component.scss']
})
export class ListComplianceComponent implements OnInit {

  userDetails: any;
  isLoading: boolean = true;

  constructor(
    public appService: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    public router: Router,
    public activateRoute: ActivatedRoute
  ) {
    this.userDetails = JSON.parse(
      localStorage.getItem('BYS-ADMIN-WEB')!
    );
    if (this.userDetails['role'] != 'Admin') {
      this.router.navigate([this.appService.sidebarOptions[0]['route']]);
    }
  }

  ngOnInit(): void {
    this.isLoading = false;
  }
  moveToPrivacyPolicy() {
    this.router.navigate([`/admin/privacy-policy`]);
  }
  moveToTermsAndCondition() {
    this.router.navigate([`/admin/terms-and-condition`]);
  }
  

}
