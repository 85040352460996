import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-suspend-business-dialog',
  templateUrl: './suspend-business-dialog.component.html',
  styleUrls: ['./suspend-business-dialog.component.scss'],
})
export class SuspendBusinessDialogComponent implements OnInit {
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<SuspendBusinessDialogComponent>,
    private toastr: ToastrService,
    public as: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    // console.log(data);
    dialogRef.disableClose = true;

    // this.userDetail = JSON.parse(localStorage.getItem("userDetails"));
  }
  ngOnInit(): void {}
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
  suspendBusiness() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Suspend Business',
      value: this.data['business'],
    });
  }
  deleteBusiness() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Business',
      value: this.data['business'],
    });
  }
}
